import Navbar from './Navbar';
import CheckGenesis from './Checkers/CheckGenesis';
import WalletBalance from './Checkers/WalletBalance';
import CheckReloaded from './Checkers/CheckReloaded';
import NFTContainer from './Containers/NFTContainer';
import {useIsMounted} from '../hooks/useIsMounted.js'
 
function Profile() {
  const mounted = useIsMounted();

  return <>
  {mounted && <div className="app">
  <Navbar />
  <div className="w-full">
    <div className="flex flex-wrap justify-between max-w-7xl mx-auto">
      <CheckGenesis />
      <WalletBalance />
      <CheckReloaded />
    </div>
  </div>

  <div className="content-center justify-center items-center w-full">
    <NFTContainer />
  </div>

  
  </div>}
  </>
}

export default Profile