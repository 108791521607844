import React from 'react'
import { useAccount } from 'wagmi'
import GenesisContainer from './GenesisContainer';
import ReloadedContainer from './ReloadedContainer';

function NFTContainer() {
  const { isConnected } = useAccount()
  return (
    <div className=" rounded-lg bg-octohedz-d m-2 content-center text-center max-w-6xl mx-auto">
        <p className="text-white text-4xl py-8">My OctoHedz</p>
        {isConnected ? 
        <>
        <GenesisContainer/>
        <ReloadedContainer/>
        </>
        : <p className="text-white text-lg p-10 italic">Connect wallet to view your holdings</p>
      }
    </div>
  )
}

export default NFTContainer