import genesisABI from '../abi/genesis.json'
import inkzABI from '../abi/inkz.json'
import reloadedABI from '../abi/reloaded.json'
import vxABI from '../abi/vx.json'

const OctoHedzAddress = "0x6e5a65b5f9dd7b1b08ff212e210dcd642de0db8b"
const OctoHedzReloadedAddress = "0x6da4c631c6ad8bfd9d7845fd093541bebece2f96"
const OctoHedzVXAddress = "0xa7aA345FfaCdaD6A7d2633C8A10897c3D46b49F7"
const InkzAddress = "0xd0c37cda48fe5d6ee7132181a90dc58206db5404"

export const octoHedzContract = {
    address: OctoHedzAddress,
    abi: genesisABI,
}
export const reloadedContract = {
    address: OctoHedzReloadedAddress,
    abi: reloadedABI,
}
export const vxContract = {
    address: OctoHedzVXAddress,
    abi: vxABI,
}
export const inkzContract = {
    address: InkzAddress,
    abi: inkzABI,
}