import { WagmiConfig, createClient } from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { mainnet } from 'wagmi/chains'
import { configureChains } from 'wagmi'
import Profile from './components/Profile';
import "./App.css"
import info from "./info.json"

const { chains, provider } = configureChains(
  [mainnet],
  [alchemyProvider({ apiKey: info.AlchemyKey }), publicProvider()],
)
 
const client = createClient({
  autoConnect: true,
  provider: provider,
})

function App() {
  return (
    <WagmiConfig client={client}>
      <Profile />
    </WagmiConfig>
  )
}

export default App;
