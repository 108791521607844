import React from 'react'
import { useAccount } from 'wagmi'
import { readContract } from '@wagmi/core'
import {reloadedContract} from '../../contracts/allContracts.js'
import {useIsMounted} from '../../hooks/useIsMounted.js'
import ReloadedCard from './../Cards/ReloadedCard';
import Alert from '@mui/material/Alert';
import ReloadedClaim from './../Buttons/ReloadedClaim';

function ReloadedContainer() {
    const mounted = useIsMounted();
    const { address, isConnected } = useAccount()
    const [nfts, setNFTs] = React.useState([])
    const [checkedValues, setCheckedValues] = React.useState([]);
    const [error, setError] = React.useState(false)

    React.useEffect(() => {
        if(isConnected) {
          const fetchNFTs = async () => {
            const holderNFTs = await readContract({
              ...reloadedContract,
              functionName: 'tokensOfOwner',
              args: [address],
            })
            setNFTs(holderNFTs)
            // console.log(holderNFTs)
          }
          fetchNFTs()
        }
      }, [address, isConnected])

      const handleCheckboxChange = (value, isChecked) => {
        if (isChecked) {
          if (checkedValues.length < 2) {
            setCheckedValues([...checkedValues, value]);
            setError(false)
          } else {
            setError(true)
            return false
          }
        } else {
          setCheckedValues(checkedValues.filter((item) => item !== value));
          setError(false)
          return true
        }
      };
    
  return (<>
    {mounted && <>
    <h1 className="text-white text-2xl text-left ml-4 my-4 p-2 bg-rel">Reloaded Collection</h1>
    {nfts?.length <= 0 && <>
    <div className="text-white italic text-base">You do not have any OctoHedz Reloaded.</div>
    <div className="text-white italic text-base">You can find the collection on 
      <a href="https://opensea.io/collection/octohedz-reloaded" rel="noreferrer" target="_blank">
        <p className="text-blue-400">Opensea</p>
      </a>
    </div></>}
    <div className="flex flex-wrap">
        {nfts.map((token, i) => (
            <ReloadedCard 
                key={token}
                id={token}
                token={token.toNumber()}
                onCheckboxChange={handleCheckboxChange}
            />
            ))}
    </div>
    {error && <Alert variant="filled" severity="error">You can only select 2 Reloaded at a time.</Alert>}
    {checkedValues.length >= 1 && <div className="text-white text-xs mt-4">Selected Reloaded: {checkedValues.join(', ')}</div>}
    <ReloadedClaim selected={checkedValues.length} nfts={checkedValues}/>
    <p className="text-white text-xs py-4">Selected 2 Unclaimed Reloaded to claim a random VX</p>
    </>}</>
  )
}

export default ReloadedContainer