import React from 'react'
import {vxContract} from '../../contracts/allContracts.js'
import { readContract } from '@wagmi/core'

function CheckReloaded() {
  const [idNumber, setIdNumber] = React.useState("")
  const [claimStatus, setClaimStatus] = React.useState(null)

  const handleCheck = async () => {
    const claimedData = await readContract({
      ...vxContract,
      functionName: 'checkIfReloadedUsed',
      args: [idNumber],
    })
    setClaimStatus(String(claimedData))
  }
  const handleChange = async (e) => {
    if(e.target.value >= 8000) {
      e.target.value = 8000
      setIdNumber(8000)
    } else if (e.target.value <= 1) {
      e.target.value = 1
      setIdNumber(1)
    } else {
      setIdNumber(e.target.value)
    }
  }
  return (
    <div className="flex flex-col items-center justify-center bg-octohedz-d p-4 w-96 h-64 claim-margin mx-auto">
      <p className="text-white my-2">Check Reloaded ID</p>
      <input className="w-64 m-2 text-center" type="number" min="1" max="8000" onChange={handleChange} placeholder="Reloaded #" />
      {claimStatus === "1" && <p className="text-red-600 my-2">VX Claimed</p>}
      {claimStatus === "0" && <p className="text-green-600 my-2">VX Unclaimed</p>}
      <button onClick={handleCheck} className="bg-octo-btn hover:bg-octo-btn text-white py-2 px-4 text-l my-2">Check</button>
    </div>
  )
}

export default CheckReloaded