import React from 'react'
import {vxContract} from '../../contracts/allContracts.js'
import { 
    useAccount, 
    usePrepareContractWrite,
    useContractWrite,
    useWaitForTransaction, 
} from 'wagmi'
import { readContract } from '@wagmi/core'
import image from '../../svg.json'

function GenesisCard({token}) {
    const nft = String(token)
    const tokenId = String(token - 1)
    const { isConnected } = useAccount()
    const [claimed, setClaimed] = React.useState(true)
    

    React.useEffect(() => {
        if(isConnected) {
          const checkClaimed = async () => {
            const claimedVX = await readContract({
              ...vxContract,
              functionName: 'checkIfVXClaimed',
              args: [tokenId],
            })
            if (String(claimedVX) === "0") {
                setClaimed(false)
            }
          }
          checkClaimed()
        }
      }, [token, tokenId, isConnected])

      // eslint-disable-next-line
      const {
        config,
        error: prepareError,
        isError: isPrepareError,
      } = usePrepareContractWrite({
        ...vxContract,
        functionName: 'mintGen',
        enabled: !claimed,
        args:[tokenId],
      })
      // console.log("prepareError" + isPrepareError)

      // eslint-disable-next-line
      const { data, error, isError, write } = useContractWrite(config)

      // eslint-disable-next-line
      const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
      })



      // console.log(claimed)
  return (
    <section className="p-2 mx-auto">
        <h1 className="text-l w-44 p-2 bg-octo-pink text-white text-center rounded-t-md">OctoHedz #{nft}</h1>
        <a href={`https://opensea.io/assets/ethereum/0x6e5a65b5f9dd7b1b08ff212e210dcd642de0db8b/${tokenId}`} rel="noreferrer" target="_blank">
            <img className="rounded-b-md w-44 h-44" src={`./images/genesis/${tokenId}.png`} alt="2"/> 
        </a>
        <button disabled={isPrepareError} className="container-vx octoz" onClick={() => write?.()} style={{display: `${claimed ? "none" : "block"}`}}>
           <div className="flex text-xs items-baseline"><img className="w-5 h-5" src={image.InkzGif} alt="inkz" /> 300 Claim VX</div>
        </button>
    </section>
  )
}

export default GenesisCard