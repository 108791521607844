import React from 'react'
import { useAccount, useContractRead } from 'wagmi'
import image from '../../svg.json'
import {inkzContract} from '../../contracts/allContracts.js'
import {useIsMounted} from '../../hooks/useIsMounted.js'
import ClaimInkz from './../Buttons/ClaimInkz';

function WalletBalance() {
  const mounted = useIsMounted();
  const { address, isConnected } = useAccount()
  const [claimableInkz, setClaimableInkz] = React.useState(0)
  const [walletBalance, setWalletBalance] = React.useState(0)

  // eslint-disable-next-line
  const { data: inkzAvailable, isLoading: inkzBalanceLoading } = useContractRead({
    ...inkzContract,
    functionName: 'getTotalClaimable',
    args: [address],
    enabled: isConnected,
    watch: true,
    onSuccess(inkzAvailable) {
      setClaimableInkz((inkzAvailable / 1000000000000000000).toString())
    },
  })
  // eslint-disable-next-line
  const { data: inkzBalance } = useContractRead({
    ...inkzContract,
    functionName: 'balanceOf',
    args: [address],
    watch: true,
    enabled: isConnected,
    onSuccess(inkzBalance) {
      setWalletBalance((inkzBalance / 1000000000000000000).toFixed(0).toString())
    },
  })


  React.useEffect(() => {
    if(!isConnected) {
      setWalletBalance("0")
      setClaimableInkz("0")
    }
  }, [isConnected])

  return (<>
    {mounted && <div className="flex flex-col items-center justify-center bg-octohedz-d w-96 h-64 claim-margin mx-auto">
      <p className="t-octohedz">Wallet Balance</p>
        <div className="flex items-center mb-4">
          <h1 className="t-octohedz text-l text-center px-2">{walletBalance}</h1>
          <img className="INKz-tiny" alt="Inkz Gif" src={image.InkzGif} />
        </div>
        <ClaimInkz claimableInkz={claimableInkz}/>
    </div>}</>
  )
}

export default WalletBalance