import React from 'react'
import { useAccount } from 'wagmi'
import { readContract } from '@wagmi/core'
import {vxContract} from '../../contracts/allContracts.js'

function ReloadedCard({token, onCheckboxChange}) {
    const tokenId = String(token)
    const { isConnected } = useAccount()
    const [claimed, setClaimed] = React.useState(true)
    const [isChecked, setIsChecked] = React.useState(false);
    

    React.useEffect(() => {
        if(isConnected) {
          const checkClaimed = async () => {
            const claimedVX = await readContract({
              ...vxContract,
              functionName: 'checkIfReloadedUsed',
              args: [tokenId],
            })
            if (String(claimedVX) === "0") {
                setClaimed(false)
            }
          }
          checkClaimed()
        }
      }, [token, tokenId, isConnected])

      const handleChange = (event) => {
        const checked = event.target.checked;
    
        if (checked) {
          const success = onCheckboxChange(tokenId, checked);
          setIsChecked(success);
        } else {
          onCheckboxChange(tokenId, checked);
          setIsChecked(checked);
        }
      };
  return (
    <section className="p-2 mx-auto">
        <h1 className="text-l w-44 p-2 bg-rel text-white text-center rounded-t-md">Reloaded #{tokenId}</h1>
        <a href={`https://opensea.io/assets/ethereum/0x6da4c631c6ad8bfd9d7845fd093541bebece2f96/${tokenId}`} rel="noreferrer" target="_blank">
            <img className="rounded-b-md w-44 h-44" src={`./images/reloaded/${tokenId}.png`} alt="2"/>
            <label className="container-vx octoz h-7" style={{display: `${claimed ? "none" : "block"}`}}>
              <p className="text-xs">use for VX</p>
                <input 
                  className="text-sm" 
                  type="checkbox"

                  onChange={handleChange}
                  />
                <span className="checkmark"></span>
            </label>
        </a>
    </section>
  )
}

export default ReloadedCard