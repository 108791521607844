import React from 'react'
import image from '../../svg.json'
import {vxContract} from '../../contracts/allContracts.js'
import { 
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from 'wagmi'

function ReloadedClaim({selected, nfts}) {
   // eslint-disable-next-line
   const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    ...vxContract,
    functionName: 'mintRel',
    enabled: selected === 2,
    args: nfts,
  })
  // console.log("prepareError" + isPrepareError)

  // eslint-disable-next-line
  const { data, error, isError, write } = useContractWrite(config)

  // eslint-disable-next-line
  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })
  return (<>
    <button onClick={() => write?.()} disabled={selected < 2} className="bg-octo-btn hover:bg-octo-btn text-white py-2 px-4 text-l m-4 btn-margin">
      <div className="flex"><img className="w-5 h-5" src={image.InkzGif} alt="inkz" />300 Reloaded Claim</div>
    </button>
    </>
  )
}

export default ReloadedClaim