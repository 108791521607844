import React from 'react'
import image from '../../svg.json'
import {inkzContract, vxContract} from '../../contracts/allContracts.js'
import { readContract } from '@wagmi/core'

function CheckGenesis() {
  const [idNumber, setIdNumber] = React.useState("")
  const [octoInkz, setOctoInkz] = React.useState("")
  const [claimStatus, setClaimStatus] = React.useState(null)
  // console.log(idNumber)

  const handleCheck = async () => {
    const inkzData = await readContract({
      ...inkzContract,
      functionName: 'octoINKz',
      args: [idNumber - 1],
    })
    const claimedData = await readContract({
      ...vxContract,
      functionName: 'checkIfVXClaimed',
      args: [idNumber - 1],
    })
    console.log(inkzData)
    setClaimStatus(String(claimedData))
    setOctoInkz(String(inkzData / 1000000000000000000))
  }

  const handleChange = async (e) => {
    if(e.target.value >= 888) {
      e.target.value = 888
      setIdNumber(888)
      // eslint-disable-next-line
    } else if (e.target.value == 0) {
      e.target.value = 0
      setIdNumber(0)
    } else {
      setIdNumber(e.target.value)
    }
  }
  return (
    <div className="flex flex-col items-center justify-center bg-octohedz-d w-96 h-64 claim-margin mx-auto">
      <p className="text-white my-2">Check Genesis ID</p>
      <input className="w-64 m-2 text-center" type="number" min="1" max="888" onChange={handleChange} placeholder="Genesis #" />
      <button onClick={handleCheck} className="bg-octo-btn hover:bg-octo-btn text-white py-2 px-4 text-l my-2">Check</button>
      {octoInkz && <p className="text-white flex items-baseline my-2"><img className="mr-2" src={image.InkzGif} alt="Inkz"/> {octoInkz}</p>}
      {claimStatus === "1" && <p className="text-red-600">VX Claimed</p>}
      {claimStatus === "0" && <p className="text-green-600">VX Unclaimed</p>}
    </div>
  )
}

export default CheckGenesis