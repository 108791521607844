import React from 'react'
import GenesisCard from './../Cards/GenesisCard';
import { useAccount } from 'wagmi'
import { readContract } from '@wagmi/core'
import {octoHedzContract} from '../../contracts/allContracts.js'
import {useIsMounted} from '../../hooks/useIsMounted.js'

function GenesisContainer() {
    const mounted = useIsMounted();
    const { address, isConnected } = useAccount()
    const [nfts, setNFTs] = React.useState([])

    React.useEffect(() => {
        if(isConnected) {
          const fetchNFTs = async () => {
            const holderNFTs = await readContract({
              ...octoHedzContract,
              functionName: 'tokensOfOwner',
              args: [address],
            })
            setNFTs(holderNFTs)
            // console.log(holderNFTs)
          }
          fetchNFTs()
        }
      }, [address, isConnected])
  return (<>
    {mounted && <div className="w-full">
    <h1 className="text-white text-2xl text-left ml-4 my-4 p-2 bg-octo-pink">Genesis Collection</h1>
    {nfts?.length <= 0 && <>
    <div className="text-white italic text-base">You do not have any OctoHedz.</div>
    <div className="text-white italic text-base">You can find the collection on 
      <a href="https://opensea.io/collection/octohedz" rel="noreferrer" target="_blank">
        <p className="text-blue-400">Opensea</p>
      </a>
    </div></>}
    <div className="flex flex-wrap">
        {nfts.map((token, i) => (
            <GenesisCard 
                key={token}
                id={token}
                token={token.toNumber() + 1}
            />
            ))}
    </div>
    </div>}</>
  )
}

export default GenesisContainer