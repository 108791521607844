import React from 'react'
import { useAccount, useConnect, useDisconnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import truncateEthAddress from 'truncate-eth-address'

function ConnectWallet() {
    const { address, isConnected } = useAccount()
    const { connect } = useConnect({
        connector: new InjectedConnector(),
    })
    const { disconnect } = useDisconnect()
    const [trunkAddress, setTrunkAddress] = React.useState("")

    React.useEffect(() => {
      if(isConnected) {
        setTrunkAddress(truncateEthAddress(address))
      }
    }, [isConnected, address])
    

    if (isConnected)
    return (
      <>
        <button className="bg-octo-btn px-6 py-2 text-2xl text-white ml-4" onClick={() => disconnect()}>Connected to {trunkAddress}</button>
      </>
    )
  return (
    <button className="bg-octo-btn px-6 py-2 text-2xl text-white ml-4" onClick={() => connect()}>Connect Wallet</button>
  )
}

export default ConnectWallet