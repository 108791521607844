import React from 'react'
import { 
    useAccount, 
    usePrepareContractWrite,
    useContractWrite,
    useWaitForTransaction,
} from 'wagmi'
import {inkzContract} from '../../contracts/allContracts.js'

function ClaimInkz({claimableInkz}) {
    const {isConnected} = useAccount()
    const inkzToClaim = parseInt(claimableInkz) > 0

    // eslint-disable-next-line
    const { config, error: prepareError, isError: isPrepareError } = usePrepareContractWrite({
        ...inkzContract,
        functionName: 'claimReward',
        enabled: isConnected && inkzToClaim
      })

      // eslint-disable-next-line
      const { data, error, isError, write } = useContractWrite(config)
      // eslint-disable-next-line
      const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
      })
  return (<>
    {inkzToClaim ? 
    <button onClick={() => write?.()} className="bg-octo-btn hover:bg-octo-btn text-white py-2 px-4 text-l mx-2">Claim {claimableInkz} $INKz</button>
    : 
    <p className="italic text-white py-2 px-4 text-l mx-2">No INKz to Claim</p>}
  </>)
}

export default ClaimInkz